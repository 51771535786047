import { FetchWithdraw, Withdraw, ConfirmWithdraw, DeclineWithdraw, ConfirmPin, FetchProviderResponse } from "@/services/withdraw";

export const withdrawToBank = (context, data) => {
    context.commit("updateLoader", { withdraw: true });
    return new Promise((resolve, reject) => {
        Withdraw(data)
            .then(res => {
                context.commit("withdraw", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { withdraw: false });
            });
    });
};

export const fetchWithdraw = (context) => {
    context.commit("updateLoader", { fetchWithdraw: true });
    return new Promise((resolve, reject) => {
        FetchWithdraw()
            .then(res => {
                context.commit("fetchWithdraw", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchWithdraw: false });
            });
    });
};

export const confirmWithdraw = (context, data) => {
    context.commit("updateLoader", { confirmWithdraw: true });
    const reference = data.reference ? data.reference : data;
    return new Promise((resolve, reject) => {
        ConfirmWithdraw({reference})
            .then(res => {
                context.commit("updateTransactionStatus", {transactionId: reference, transaction: res.data.data});
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { confirmWithdraw: false });
            });
    });
};


export const declineWithdraw = (context, data) => {
    context.commit("updateLoader", { declineWithdraw: true });
    const reference = data.reference ? data.reference : data;
    return new Promise((resolve, reject) => {
        DeclineWithdraw({reference})
            .then(res => {
                context.commit("updateTransactionStatus", {transactionId: reference, transaction: res.data.data});
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { declineWithdraw: false });
            });
    });
};

export const confirmPin = (context, data) => {
    context.commit("updateLoader", { confirmPin: true });
    return new Promise((resolve, reject) => {
        ConfirmPin(data)
            .then(res => {
                // context.commit("confirmPin", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { confirmPin: false });
            });
    });
};

export const fetchProviderResponse = (context, transaction) => {
    context.commit("updateLoader", { fetchProviderResponse: true });
    return new Promise((resolve, reject) => {
        FetchProviderResponse(transaction)
            .then(res => {
                context.commit("fetchProviderResponse", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchProviderResponse: false });
            });
    });
};

const WithdrawAction = {
    withdrawToBank,
    fetchWithdraw,
    confirmWithdraw,
    declineWithdraw,
    confirmPin,
    fetchProviderResponse
};

export default WithdrawAction;