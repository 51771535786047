import http from "./http";

export const Withdraw = async data => {
    return await http.post("/withdraw", data);
};

export const FetchWithdraw = async() => {
    return await http.get("/withdraw");
};

export const ConfirmWithdraw = async data => {
    return await http.patch("/withdraw/confirm", data);
};

export const DeclineWithdraw = async data => {
    return await http.patch("/withdraw/decline", data);
};

export const ConfirmPin = async data => {
    return await http.post("/wallet/pin-confirm", data)
}

export const FetchProviderResponse = async transaction => {
    return await http.get(`/withdraw/${transaction}/provider`);
}