<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-4 py-3">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xs"><i class="pi pi-bolt"></i>Dispute Summary</h6>
        <button
          class="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          @click="(e) => $emit('close', e)"
        >
          <i class="fa fa-times"></i> 
        </button>
      </div>
    </div>
    
    <div class="flex-auto px-4 lg:px-4 py-10 pt-0">
      <div v-if="dispute.id">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6">
          <i class="pi pi-info-circle"></i> Dispute Resolution
        </h6>

        <div class="relative mb-3 px-1 mt-3">
          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 text-xs px-3 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button" 
            @click="handleConfirmWithdraw" 
            :disabled="loaders().updateDispute || loaders().confirmWithdraw"
            v-if="has('confirm.withdraw.ability')"
          >
          <i class="pi pi-refresh text-xs"></i> mark as successful
          </button>

          <button
            class="bg-red-500 text-white active:bg-red-600 text-xs px-3 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button" 
            @click="handleDeclineWithdraw" 
            :disabled="loaders().updateDispute || loaders().declineWithdraw"
            v-if="has('confirm.withdraw.ability')"
          >
          <i class="pi pi-refresh text-xs"></i> mark as failed
          </button>

          <button
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs px-3 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button" 
            @click="reverseTransaction" 
            :disabled="loaders().updateDispute || loaders().reverse"
            v-if="has('wallet.debit.ability')"
          >
          <i class="pi pi-refresh text-xs"></i> refund
          </button>

          <button
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs px-3 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button" 
            @click="reQuery" 
            :disabled="loaders().fetchProviderResponse"
          >
          <i class="pi pi-refresh text-xs"></i> re-query
          </button>
        </div>

      </div>

      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6">
          <i class="pi pi-info-circle"></i> Status Update
        </h6>
        <hr class="my-2 border-b-1 border-blueGray-300" />
        <div class="flex flex-wrap">
          <div class="w-full px-1">
            <div class="relative w-full mb-1">
              <label
                class="block text-blueGray-600 text-xs mb-2"
                htmlFor="grid-password"
              >
                Status
              </label>
              <select v-model="v$.dispute.status.$model" class="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-xs shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                <option v-for="(status, i) in ['CREATED', 'OPEN', 'CLOSED', 'RESOLVED', 'SUCCESSFUL', 'FAILED']" :key="i" :value="status">
                {{ status.toLowerCase() }}
                </option>
              </select>

              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.dispute.status.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <div class="relative w-full mb-3">
              <label
                class="block text-blueGray-600 text-xs mb-2"
                htmlFor="grid-password"
              >
                Comment (Optional)
              </label>
              <input 
                v-model="v$.dispute.response.$model" 
                class="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-xs shadow focus:outline-none   w-full ease-linear transition-all duration-150" 
                type="text"
              />

              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.dispute.response.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="relative mb-3 px-1 mt-3">
          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 text-xs px-3 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button" 
            @click="updateExistingPlan" 
            :disabled="loaders().updateDispute || loaders().saveDispute"
            v-if="has('disputes.update') || has('disputes.save')"
          >
          <i class="pi pi-refresh text-xs"></i> {{ dispute.id ? 'Update' : 'Save' }}
          </button>
        </div>
      </form>
      <hr/>
      <div class="overflow-x-scroll">
        <recursive-table 
          :data="dispute"
          :excludeAll="['id', 'uuid', 'user_id', 'business_id']"
          :exclude="['role_id', 'logged_by', 'resolved_by', 'transaction.meta', 'transaction.provider_log']"
        />
      </div>
    </div>

    <transition name="bounce" :duration="{ enter: 500, leave: 800 }">
      <div v-if="modals.re_query" style="width: 100%;" @click.self="$_close_all_modal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
        <div class="relative w-auto z-50">
          <!--content-->
          <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <!--header-->
            <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 class="text-lg capitalize">
                <i class="pi pi-info-circle"></i> Provider information
              </h3>
              <button 
                class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
                v-on:click="$_toggle_modal('re_query', false)"
              >
                <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            <!--body-->
            <recursive-table 
              :data="re_query"
              :excludeAll="['id']"
              v-if="!loaders().fetchProviderResponse"
            />
            <div v-else class="p-5">
              <i>Please wait. Loading...</i>
            </div>
            <div class="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b">            
              <button 
                class="text-red-500 background-transparent px-3 py-1 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
                v-on:click="$_toggle_modal('re_query', false)"
              >
                close
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div v-if="modals.re_query" @click="$_close_all_modal" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-30 h-screen top-0 left-0 bg-black"></div>

  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import { createToaster } from "@meforma/vue-toaster";
import { strings, modal } from '@/mixins'
import RecursiveTable from '@/components/Tables/RecursiveTable.vue';
import { confirmAction, transactionList } from '@/utils'
// import EmptyList from "@/components/Cards/EmptyList.vue";
const toaster = createToaster({ /* options */ });
// https://ims-internal.azurewebsites.net/InternalCore/Timetable
// Sign in to your account
 
export default {
  props: [ "selectedDispute" ],
  components: {
    RecursiveTable,
    // EmptyList
  },
  mixins: [strings, modal],
  setup () {
    return { v$: useVuelidate() }
  },
  watch: {
    selectedDispute(dispute) {
      this.dispute = dispute
      this.parseBoolean(dispute)
    }
  },
  data() {
    return {
      transactionList,
      modals: {},
      dispute: this.selectedDispute || {},
      re_query: {}
    }
  },
  validations() {
    return {
      dispute: {
        status: { required },
        response: {}
      }
    }
  },
  methods: {
    ...mapGetters(["loaders", "roles"]),
    ...mapActions([
      "reverse",
      "saveDispute", 
      "updateDispute",
      "confirmWithdraw",
      "declineWithdraw",
      "fetchProviderResponse"
    ]),
    updateExistingPlan() {
      const data = this.dispute;

      confirmAction({
        confirmText: `Are you sure you want to ${data.id ? 'update' : 'create'} this plan?`,
        successText: `The plan has been ${data.id ? 'updated' : 'created'}`,
        cancelText: `${data.id ? 'Updating' : 'Creation'} of plan aborted`
      }, () => {
        const q = data.id ? this.updateDispute(data) : this.saveDispupdateDispute(data);
        q.then( () => {}).catch(() => {
          toaster.error('An error occored, please try again.')
        })
      });
    },
    parseBoolean(dispute) {
      this.dispute.is_active = !!dispute.is_active
    },
    async reQuery() {
      this.$_toggle_modal('re_query', true)
      const provider_log = await this.fetchProviderResponse(this.selectedDispute.transaction.id)
      if (typeof provider_log === "string") {
        try {
          this.re_query = JSON.parse(provider_log);
        } catch (e) {
          this.re_query = provider_log
        }
      } else {
        this.re_query = provider_log
      }
    },
    async reverseTransaction() {
      const transaction = this.dispute.transaction;

      confirmAction({
        confirmText: "Please confirm the reversal of this transaction",
        successText: "This transaction was successfully refunded",
        cancelText: "This action was aborted"
      }, () => {
        this.reverse(transaction)
      });
    },
    handleConfirmWithdraw() {
      const transaction = this.dispute.transaction;
      confirmAction({
        confirmText: "Confirm the confirmation of this transaction",
        successText: "This transaction has been marked as successfull",
        cancelText: "This action was aborted"
      }, () => {
        this.confirmWithdraw(transaction).then( () => {
          toaster.success("Successfully confirmed transaction.")
        })
      });
    },
    handleDeclineWithdraw() {
      const transaction = this.dispute.transaction;
      confirmAction({
        confirmText: "Confirm the declination of this transaction",
        successText: "This transaction has been marked as failed",
        cancelText: "This action was aborted"
      }, () => {
        this.declineWithdraw(transaction).then( () => {
          toaster.success("Successfully declined transaction.")
        })
      });
    },
  },
  mounted() {
    this.parseBoolean(this.selectedDispute)
  }
}
</script>